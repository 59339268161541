import store from "@/store";
import { createRouter, createWebHistory,  Router, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import {App, nextTick} from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      title: "Home",
    },
  },
  {
    path: "/invoices",
    name: "Invoices",
    component: () => import("../views/Invoice.vue"),
    meta: {
      requiresAuth: true,
      title: "Invoices",
    },
    props: true,
  },
  {
    path: "/details",
    name: "Details",
    component: () => import("../views/InvoiceDetail.vue"),
    meta: {
      requiresAuth: true,
      title: "Details",
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/Settings.vue"),
    meta: {
      requiresAuth: true,
      title: "Settings",
    },
  },
  {
    path: "/sales",
    name: "Sales",
    redirect:'/sales/parts',
    component: () => import("../views/Sales.vue"),
    children: [
      {
        name: "Parts",
        path: "/sales/parts",
        component: () => import("../components/Orders/Pos.vue"),
        props: true,
      },
      {
        name: "Shipping Information",
        path: "/sales/shipping",
        component: () => import("../components/Orders/ShippingInfo.vue"),
        props: true,
      },
      {
        name: "Payment",
        path: "/sales/payment",
        component: () => import("../components/Orders/Pay.vue"),
        props: true,
      },
      {
        name: "Confirmation",
        path: "/sales/confirmation",
        component: () => import("../components/Orders/Confirmation.vue"),
        props: true,
      }
    ],
    meta: {
      requiresAuth: true,
      title: "Sales",
    }
  },
  {
    path: "/printable-view",
    name: "Printable View",
    component: () => import("../components/PrintableDataTable.vue"),  
    meta: {
      requiresAuth: true,
      title: "Printable View",
    },
  },
  {
    name: "Quotes",
    path: "/quotes",
    props: true,
    component: () => import("../views/Quotes.vue"),
    meta: {
      requiresAuth: true,
      title: "Quotes",
    }
  },
  {
    name: "Edit Quote",
    path: "/sales/edit-quote",
    props: true,
    component: () => import("../components/Orders/EditQuote.vue"),
    meta: {
      requiresAuth: true,
      title: "Sales",
    }
  },
  {
    path: "/tickets",
    name: "Billing",
    component: () => import("../views/Tickets.vue"),
    meta: {
      requiresAuth: true,
      title: "Billing",
    },
    props: true,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/404.vue"),
  },
];

export interface RoverRouter extends Router {
  superInstall?: (app: App) => void;
}


const router: RoverRouter = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.superInstall = router.install;

router.install = function (app) {
  router.superInstall && router.superInstall(app);
  
  router.beforeEach(async (to, from, next) => {
    const path = to.path;
      next();
  });

  router.afterEach((to, from, failure) => {
    if (!failure) {
      nextTick(() => {
        app.config.globalProperties.$posthog.capture("$pageview", {
          path: to.fullPath,
        });
      });
    }
  });

router.beforeResolve(async (to, from, next) => {
  const requiresAuth = to.meta.requiresAuth;
  let roverJwt;
  let user;
  let name;
  let customers;
  let loginUrl;
  let theme;
  let subKey;
  if (to.query.session) {
    const session = Array.isArray(to.query.session)
      ? ""
      : JSON.parse(atob(to.query.session.toString()));
    user = session.user;
    subKey = session.subKey;
    store.commit("session/SET_SUB_KEY", subKey)
    roverJwt = session.roverJwt;
    store.commit("session/SET_ROVER_JWT", roverJwt);
    user = await store.dispatch("session/getContact", { id: user.contact_id, client: session.name });
    name = session.name;
    store.commit("session/SET_CLIENT_NAME", name);
    store.dispatch("payment/fetchPaymentInfo", {
      option: "IFRAMEURL ACHFLAG",
      Client: name,
    });
    customers = session.customers;
    await store.dispatch("session/setCust", customers);
    await store.dispatch("session/setCoCodes", user.allowedCoCodes);
    store.dispatch("control/setSelectedCoCode", store.getters["customer/getCust"].co_code);
    theme = session.theme;
    store.dispatch("theme/setTheme", theme);
    loginUrl = session.loginUrl;
    await store.dispatch("env/fetchModules");
    await store.dispatch("session/setInitialDataLoaded",true);
    store.commit("session/SET_LOGIN_URL", loginUrl);

    app.config.globalProperties.$posthog.identify(
      `${user?.email_address_items[0]?.email_address}-${session.subsciptionClient}`,
      { userId: user?.contact_id, email: user?.email_address_items[0]?.email_address},
    );
    
  } else {
    user = store.getters["session/getUser"];
  }
  const company = store.getters["control/getCompany"];
  if (!company.control_id) {
    // only fetch company if not already fetched
    await store.dispatch("control/fetchControls", { id: "COMPANY", procedure: "CO.CONTROL", filename: "CONTROL" });
  }
  const url = store.getters["session/getLogin"];
  // If no user is logged in, route to login or 404
  if (!user) {
    if (url) {
      location.replace(url.toString());
    } else {
      next({ name: "404" });
    }

    // If the user is already logged in and trying to go to the login page, route to main menu
  } else if (to.path === "/" && user) {
    next({ name: store.getters["env/getModules"][0] || "Invoices" });
  } else {
    next();
  }
  document.title = store.getters["session/getClient"] + " Customer Portal - " + to.meta.title;
});
};

export default router;

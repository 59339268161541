import { AxiosInstance } from "axios";
import axios from "axios";
import Customer from "@/types/customer";
import store from "../store"

export default class CustomerService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getCustomer(id: any, client: any, correls?: any, co_code?: any) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    return this.submit(`customer/${id}`, authToken, {Client: client, correls: correls, co_code: co_code});
  }

  async fetchCustOptions(selection: string) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    const params = {
      selection,
    };
    return this.submit("customers", authToken, params);
  }

  async putCustomer(id: string, client: string, newCust: Customer, oldCust: Customer) {
    let token = ""
    await store.dispatch('session/fetchToken').then((response) => { token = response })
    const authToken = "Bearer " + token
    const body = {
      newCust,
      oldCust,
    };
    const params = {
      client,
    };

    return this.submitPut(`/customer/${id}`, authToken, params, body);
  }

  private async submit(endpoint: string, token: string, params?: any) {
    return new Promise((resolve, reject) => {
      this.client
        .get(endpoint, 
          { 
            params,
            headers: 
            {
              'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
              'Authorization': token
            }
          })
          .then((response) => {
              resolve(response.data);
            })
        .catch((error) => reject(error));
    });
  }

  private submitPut(endpoint: string, token: string, params: any, payload: any) {
    return new Promise((resolve, reject) => {
      this.client
        .put(endpoint, payload, 
          { 
            params,
            headers: 
            {
              'Ocp-Apim-Subscription-Key': store.getters['session/getSubKey'],
              'Authorization': token
            }
          })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

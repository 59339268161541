import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate"
import Cookies from 'js-cookie'
// Modules
import { notification } from "@/store/modules/notification";
import { invoice } from "@/store/modules/invoice";
import { customer } from "@/store/modules/customer";
import { session } from "@/store/modules/session";
import { sidemenu } from './modules/sidemenu';
import { payment } from './modules/payment';
import { theme } from './modules/theme';
import { RootState } from "@/types/state";
import { billing } from "@/store/modules/billing";
import {sales} from "@/store/modules/sales";
import {pos} from "@/store/modules/ui/pos";
import { parts } from "@/store/modules/parts";
import { control } from "@/store/modules/control";
import { terms } from "@/store/modules/terms";
import { env } from "@/store/modules/env";
import { printableDatatable } from '@/store/modules/printableDatatable';
import { fdict } from "@/store/data/fileDictionaries";
const fourHours = 1 / 6;

export default createStore<RootState>({
  modules: {
    notification,
    invoice,
    customer,
    session,
    sales,
    pos,
    control,
    sidemenu,
    payment,
    terms,
    theme,
    billing,
    parts,
    env,
    printableDatatable,
    fdict
  },
  plugins: [
    createPersistedState(
      {
        storage:
          window.sessionStorage
      }),
    createPersistedState(
      {
        key: 'loginUrl',
        paths: [
          'session.loginUrl',
        ],
        storage: {
          getItem: (key) => Cookies.get(key),
          setItem: (key, value) =>
            Cookies.set(key, value, { expires: 365, secure: true }),
          removeItem: (key) => Cookies.remove(key),
        }
      }),
    ],
});
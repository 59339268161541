import User from "@/types/user";
import BaseCustomer from "@/types/basecustomer";
import Token from "@/types/token";

export default interface UserState {
    user: User | null;
    customers: Array<BaseCustomer> | null;
    co_codes: Array<any> | null;
    client: string | null;
    loginUrl: string | null;
    loading: boolean;
    token: Token | null;
    subKey: string | null;
    tokenTimeStamp: any;
    tokenFetchInProgress: Promise<any> | null;   
    initialDataLoaded: boolean; 
    roverJwt: string | null;
}

export const FETCH_TOKEN = "session/fetchToken";
export const GET_ROVER_JWT = "session/getRoverJwt";
export const GET_SUB_KEY = "session/getSubKey";


import { mapActions, mapGetters } from "vuex";
import Dropdown from "primevue/dropdown";
import { defineComponent } from "vue";
import User, { AddressItem } from "@/types/user";
import Customer from "@/types/customer";
import InputText from "primevue/inputtext";
import FieldSet from "primevue/fieldset";
import Button from "primevue/button";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import Utils from "@/utility/utils";
import { FDICT_SO } from "@/utility/fdicts/so";

export default defineComponent({
  name: "Settings",
  components: {
    Dropdown,
    InputText,
    FieldSet,
    Button,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      selectedCust: "",
      selectedBranch: "",
      contact: {} as User,
      address: "",
      email: "",
      loading: false,
      customer: {} as Customer,
      custAddr: "",
      custLoading: false,
    };
  },
  validations() {
    return {
      email: { required, minLength: minLength(1) },
    };
  },
  created() {
    this.setContact();
    this.setCust();
  },
  computed: {
    ...mapGetters({
      getCustomers: "session/getCustomers",
      getUser: "session/getUser",
      getCust: "customer/getCust",
      getCoCodes: "session/getCoCodes",
      getFieldLabel: "fdict/getFieldLabel",
      getSelectedCoCode: "control/getSelectedCoCode",
    }),
    customerAddress(): any {
      return Utils.customerShippingAddress(this.customer);
    },
    companyCodeLabel(): string {
      // TODO - Create object map for company code to field number
      return this.getFieldLabel("SO", FDICT_SO.CO_CODE, "Company Code");
    },
  },

  methods: {
    ...mapActions({
      fetchCustomer: "customer/fetchCustomer",
      updateContact: "session/updateContact",
      setSelectedCoCode: "control/setSelectedCoCode",
      clearOrder: "pos/clearOrder",
    }),
    changeCode(event: { value: any }) {
        this.setCode(event.value);
        this.clearOrder();
        this.$router.push('/sales/parts');
    },
    changeCustomer(event: { value: any }) {
      this.fetchCustomer({
        id: event.value,
        correls: "bank_token"
      }).then(() => {
        this.setCust()
        this.setSelectedCoCode(this.getCust.co_code);
        this.$router.push('/invoices')
      });
    },
    setCode(value : any) {
        const code = value;
        this.setSelectedCoCode(code);
    },
    setContact() {
      this.contact = JSON.parse(JSON.stringify({ ...this.getUser })) as User;
      if (!this.contact!.address_items) {
        this.address = "";
      } else {
        let addressStr = [] as Array<string>;

        this.contact.address_items.forEach((item: AddressItem) => {
          addressStr.push(item.address as string);
        });
        this.address = addressStr.join(" ");
      }

      this.email = this.contact.email_address_items[0].email_address;
    },
    setCust() {
      this.customer = JSON.parse(JSON.stringify(this.getCust));
      this.selectedCust = this.getCust.cust_id;
      let addressList = [] as string[];
      if (this.customer.address_items != null) {
        addressList = this.customer.address_items.map((item: AddressItem) => {
          return item.address;
        });
      }

      this.custAddr = addressList.join(" ");
    },
    /*
     * @desc checks that form is valid with the validate function
     * @desc if succesful makes a post request to save contact information
     * @desc triggered by button
     *
     */
    async saveContact() {
      this.loading = true;
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        this.contact.email_address_items = this.emailSetup();
        const addressItems = [{ address: this.address }];
        this.contact.address_items = addressItems;
        this.updateContact({ contact: this.contact });
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    saveCust() {
      this.custLoading = true;
      const customer = this.getCust;
      const oldCust = {
        cust_id: customer.cust_id,
        name: customer.name,
        address_items: customer.address_items,
        phone: customer.phone,
      };
      let newAddr = [{ address: this.custAddr }];
      const newCust = {
        address_items: newAddr,
        phone: customer.phone,
        cust_id: this.customer.cust_id,
      };
      const payload = {
        oldCust,
        newCust,
      };
      this.updateContact({
        id: this.customer.cust_id,
        newCust,
        oldCust,
      })
        .then(() => {
          this.custLoading = false;
        })
        .catch(() => {
          this.custLoading = false;
        });
    },
    /*
     * @desc filters email array to only send email items with non empty strings
     * @returns {Array} new formatted array
     */
    emailSetup() {
      const emails = this.contact.email_address_items.filter((item) => {
        return item.email_address != "";
      });

      return emails;
    },
  },
});

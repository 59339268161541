import Customer from "@/types/customer";

export default class Utils {
  static formatDate(date: Date | string) {
    if (date instanceof Date) {
      return date
        .toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
        })
        
    } else {
      return new Date(date)
        .toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
        })
        
    }
  }

  static formatTime(date: Date | string) {
    if (date instanceof Date)
      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });
    else
      return new Date(date).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });
  }

  static booleanCheck(value?: string) {
    if (!value) {
      return false;
    }

    const options = ["Y", "YES", "TRUE", "1"];
    return options.includes(value.toUpperCase());
  }
  
  static formatDictionaryName(name: string) {
    if (name) {
      return name
        .split('.')
        .map((word) => {
          const w = word.toLowerCase();
          return w[0].toUpperCase() + w.substring(1);
        })
        .join(' ');
    } else {
      ('');
    }
  }

  static parseErrorMessage(error: any): string | Array<string> {
    if (
      error?.response?.data?.error &&
      typeof error.response.data.error === "string"
    ) {
      return error.response.data.error;
    } else if (error?.response?.data?.message) {
      return error.response.data.message;
    } else if (error?.error) {
      return error.error;
    } else if (error?.message) {
      return error.message;
    } else {
      return "";
    }
  }

  static formatPrice(amount: string | number) {
    let amt = typeof amount === 'string' ? parseFloat(amount) : amount;
    if (amt && typeof amt === 'number') {
        return amt.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
    } else {
        if (isNaN(amt)) {
            amt = 0;
        }
        return amt.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
    }
  }

  static base64ToArrayBuffer(data: string): Uint8Array {
    const bString = window.atob(data);
    const bLength = bString.length;
    const bytes = new Uint8Array(bLength);
    for (let i = 0; i < bLength; i++) {
      const ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  static focusAndCenterElement(ref: any): void {
    const element = ref

    if (element instanceof HTMLElement) {
      element.focus();
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  static calculateQtyStep(part: any): number { 
    let step = 1;
    if (part && part.sell_qty) {
      step = parseFloat(part.sell_qty);
    }
    return step;
  }

  static customerShippingAddress(customer: Customer): Array<string> {
    let s: Array<string> = [];
    const shippingAddress = customer.ship_seq_items;
    const validShippingAddress = (shippingAddress && shippingAddress.length > 0) && (shippingAddress[0].ship_to_items && shippingAddress[0].ship_to_items.length > 0);

    if(validShippingAddress) {
      const shipAddress = shippingAddress[0];
      s = shipAddress?.ship_to_items.map(item => item.ship_to) as string[] || [];
      const city = shipAddress.ship_city || "";
      const state = shipAddress.ship_state || "";
      const zip = shipAddress.ship_zip || "";
      const country = shipAddress.ship_country || "";
      const validCityStateZip = city && state && zip;
      if(validCityStateZip) {
        s.push(city + ", " + state + " " + zip);
      }

      if(country) {
        s.push(country);
      }
    } else {
      const addressItems = customer.address_items;
      s = addressItems?.map(item => item.address) as string[] || [];
      const city = customer.sort_city || "";
      const state = customer.sort_state || "";
      const zip = customer.sort_zip || "";
      const country = customer.sort_country || "";
      const validCityStateZip = city && state && zip;
      if(validCityStateZip) {
        s.push(city + ", " + state + " " + zip);
      }

      if(country) {
        s.push(country);
      }
    }

    return s;
  }
}

import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import SessionState from "@/types/state/session";
import ContactService from "@/services/ContactService";
import CustomerService from "@/services/CustomerService";
import { resolveComponent } from "vue";
import TokenService from "@/services/TokenService";
import store from "@/store";
const namespaced = true;

const service = new ContactService(process.env.VUE_APP_ABSTRACTION_API);
const customerService = new CustomerService(process.env.VUE_APP_ABSTRACTION_API);
const tokenService = new TokenService(process.env.VUE_APP_AZURE_FUNC_URL);

export const state: SessionState = {
  user: null,
  customers: null,
  co_codes: null,
  client: null,
  loginUrl: null,
  loading: false,
  token: null,
  subKey: null,
  tokenTimeStamp: null,
  tokenFetchInProgress: null,
  initialDataLoaded: false,
  roverJwt: null,
};

export const getters: GetterTree<SessionState, RootState> = {
  getUser(state): any {
    return state.user;
  },
  getLogin(state): any {
    return state.loginUrl;
  },
  getRoverJwt(state): any {
    return state.roverJwt;
  },
  getCustomers(state): any {
    if (state.customers !== null) {
      return state.customers;
    }
  },
  getFirstCust(state): any {
    if (state.customers !== null) {
      return state.customers[0];
    }
  },
  getCoCodes(state): any {
    return state.co_codes;
  },
  getClient(state): any {
    return state.client;
  },
  getdataLoaded(state): any {
    return state.initialDataLoaded;
  },
  getToken(state): any {
    return state.token;
  },
  getSubKey(state): any {
    return state.subKey;
  },
  getTokenTimeStamp(state): any {
    return state.tokenTimeStamp;
  }
};

export const mutations: MutationTree<SessionState> = {
  //to change the state
  DO_LOGIN(state, user) {
    state.user = user;
  },
  SET_CUST(state, custs) {
    state.customers = custs;
  },
  SET_ROVER_JWT(state, roverJwt) {
    state.roverJwt = roverJwt;
  },
  SET_CO_CODES(state, co_codes) {
    state.co_codes = co_codes;
  },
  DO_LOGOUT(state) {
    state.user = null;
    state.customers = null;
    state.client = null;
    state.loading = false;

  },
  SET_CLIENT_NAME(state, client) {
    state.client = client;
  },
  SET_LOGIN_URL(state, url) {
    state.loginUrl = url;
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_INITIAL_DATA_LOADED(state,dataLoaded) {
    state.initialDataLoaded = dataLoaded
  },
  SET_SUB_KEY(state, subKey) {
    state.subKey = subKey
  },
  SET_TOKEN_TIMESTAMP(state, timer) {
    state.tokenTimeStamp = timer
  },  
  SET_TOKEN_FETCH_IN_PROGRESS(state, promise) {
    state.tokenFetchInProgress = promise;
  },
  CLEAR_TOKEN_FETCH_IN_PROGRESS(state) {
    state.tokenFetchInProgress = null;
  },
};

export const actions: ActionTree<SessionState, RootState> = {
  logout({ state, commit }) {
    commit("DO_LOGOUT");
    window.sessionStorage.removeItem('vuex')
    if (state.loginUrl) {
      location.replace(state.loginUrl.toString());
    }
  },
async fetchToken({ state, commit, dispatch }) {
  return new Promise((resolve, reject) => {
      if (state.tokenTimeStamp + ((state.token?.expires_in || 0) - (5 * 60)) < Math.round(Date.now() / 1000)) {
          
          // If token fetch is already in progress, just wait for it.
          if (state.tokenFetchInProgress) {
              state.tokenFetchInProgress
                  .then((token: any) => {
                      resolve(state.token?.access_token);
                  })
                  .catch((error) => {
                      reject({ success: false, error: error });
                  });
              return;
          }
          
          // If not, initiate the fetch and store the promise.
          const fetchInProgress = tokenService
              .fetchToken()
              .then((response: any) => {
                  if (!response.error) {
                      commit("SET_TOKEN", response);
                      commit("SET_TOKEN_TIMESTAMP", Math.round(Date.now() / 1000));
                      resolve(response.access_token);
                  } else {
                      dispatch(
                          "notification/add",
                          {
                              message: "Authentication error. Please contact your administrator",
                              type: "error",
                          },
                          { root: true }
                      );
                      reject({ success: false, error: "Authentication error" });
                  }
              })
              .catch((error) => {
                  dispatch(
                      "notification/add",
                      {
                          message: `An error has occured: ${error}`,
                          type: "error",
                      },
                      { root: true }
                  );
                  reject({ success: false, error: error });
              })
              .finally(() => {
                  // Once fetch completes, clear the tokenFetchInProgress.
                  commit("CLEAR_TOKEN_FETCH_IN_PROGRESS");
              });

          // Save the promise to state.
          commit("SET_TOKEN_FETCH_IN_PROGRESS", fetchInProgress);

      } else {
          resolve(state.token?.access_token);
      }
  });
},
setInitialDataLoaded({ state, dispatch, commit }, dataLoaded) {
  return new Promise((resolve, reject) => {
  commit("SET_INITIAL_DATA_LOADED", dataLoaded);
  resolve(true);
  });
},
setCoCodes({ state, commit }, co_codes) {
  commit("SET_CO_CODES", co_codes);
},
setCust({ state, dispatch, commit }, customers) {
    return new Promise((resolve, reject) => {
    commit("SET_CUST", customers);
    customerService.getCustomer(customers[0].cust_id, "", "bank_token")
    .then((response:any) => {
  //  dispatch("customer/setCustomer", response);
      store.dispatch('customer/setCustomer', response);
      resolve(true);
    })
    .catch((error) => {
      reject({ success: false, error: error });
    });
  });
},
  updateContact({ state, commit, dispatch }, { contact}) {
    service
      .putContact(contact, state.user)
      .then((response) => {
        commit("DO_LOGIN", contact);
        const notification = {
          type: "success",
          message: "Contact Information Saved",
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch((error) => {
        const notification = {
          type: "error",
          message: error,
        };
        dispatch("notification/add", notification, { root: true });
      });
  },
  getContact({ state, commit, dispatch }, { id }) {
    return new Promise((resolve, reject) => {
      service.getContact(id).then((response: any) => {
        commit("DO_LOGIN", response.contact_items[0]);
        resolve(response.contact_items[0]);
      });
    });
  },
};

export const session: Module<SessionState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

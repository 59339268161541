import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState } from "@/types/state";
import InvoiceState from "@/types/state/invoice";
import Invoice from "@/types/invoice";
import Pdf from "@/types/pdf";
import InvoiceService from "@/services/InvoiceService";
import Utils from '@/utility/utils';

const namespaced = true;
const service = new InvoiceService(process.env.VUE_APP_ABSTRACTION_API);

export const state: InvoiceState = {
  invoices: [],
  loading: false,
  page: 0,
  rangeStart: 1,
  rangeEnd: 100,
  isLast: false,
  invoiceView: [],
  loadingInvoices: [],
  sortedBalance: false,
  sortedDueDate: true,
  sortingString: "due.date",
  sortingOrder: "DEC",
  dateFilter: null,
  statusFilter: "O",
  itemsPerPage: 5,
  invoicesToPay: [],
  monthlyData: [],
};

export const getters: GetterTree<InvoiceState, RootState> = {
  getInvoices(state): Array<Invoice> {
    return state.invoices;
  },
  getMonthlyData(state) {
    return state.monthlyData
  },
  getLoadingStatus(state): boolean {
    return state.loading;
  },
  getRange(state): number {
    return state.rangeEnd;
  },
  getPage(state): number {
    return state.page;
  },
  getLength(state): number {
    return state.invoices.length;
  },
  getInvoice(state): Array<Pdf> {
    return state.invoiceView;
  },
  getItemsPerPage(state): number {
    return state.itemsPerPage;
  },
  getInvoicesToPay(state): Array<any> {
    return state.invoicesToPay;
  },
  getLoadingInvoices(state) {
    return state.loadingInvoices;
  },
};

export const mutations: MutationTree<InvoiceState> = {
  SET_INVOICE(state, obj) {
    state.invoiceView.push(obj);
  },
  SET_MONTHLY_DATA(state, data) {
    state.monthlyData = data
  },
  PUSH_INVOICE(state, invoice) {
    state.invoices.push(...invoice);
  },
  TOGGLE_LOADING(state) {
    state.loading = !state.loading;
  },
  NEXT_RANGE(state) {
    state.rangeStart += 100;
    state.rangeEnd += 100;
  },
  NEXT_PAGE(state, page) {
    state.page = page;
  },
  CLEAR_INVOICES(state) {
    state.invoices = [];
  },
  RESET_PAGE(state) {
    state.page = 0;
    state.rangeStart = 1;
    state.rangeEnd = 100;
  },
  SET_ITEMS_PER_PAGE(state, items) {
    state.itemsPerPage = items;
  },
  PUSH_INCOIVE_TO_PAY(state, invoice) {
    state.invoicesToPay.push(invoice);
  },
  REMOVE_INVOICE_TO_PAY(state, invoice) {
    const filteredarray = state.invoicesToPay.filter((inv) => {
      return invoice.arId != inv.arId;
    });
    state.invoicesToPay = filteredarray;
  },
  ADD_ID_LOADING(state, id) {
    state.loadingInvoices.push(id);
  },
  REMOVE_ID_LOADING(state, id) {
    const index = state.loadingInvoices.indexOf(id, 0);
    if (index > -1) {
      state.loadingInvoices.splice(index, 1);
    }
  },
  CLEAR_INVOICESTOPAY(state) {
    state.invoicesToPay = [];
  },
  SET_INVOICES(state, invoices) {
    state.invoices = invoices;
  },
};

export const actions: ActionTree<InvoiceState, RootState> = {
  setItemsPerPage({ commit }, itemsPerPage) {
    commit("SET_ITEMS_PER_PAGE", itemsPerPage);
  },
  setNextPage({ commit }, page) {
    commit("NEXT_PAGE", page);
  },
  async fetchMonthlyData({commit}, {cust, month, year}) {
      service
        .getKpiInfo(cust, month, year)
        .then((response: any) => {
          commit("SET_MONTHLY_DATA", response.data);
        })
        .catch((error) => {
          console.log(error)
        });
  }, 
  async setInvoices(
    { commit, dispatch, state },
    { custId, dateRange, status, sortBy, sortOrder, addInvoices, id, type }
  ) {
    return new Promise((resolve, reject) => {
    commit("TOGGLE_LOADING");
    if (!addInvoices) {
      commit("CLEAR_INVOICES");
    }
    let dateStart = undefined;
    let dateEnd = undefined;

    if(sortOrder === "" && sortBy === "") {
      sortOrder = "DEC"
      sortBy = "invoice.amt"
    }

    if (dateRange !== null) {
      dateStart = new Date(dateRange[0])
        .toLocaleString()
        .split(",")[0]
        .toString();
      if(!dateRange[1]) {
        dateEnd = dateStart
      } else {
        dateEnd = new Date(dateRange[1])
          .toLocaleString()
          .split(",")[0]
          .toString();
      }
    }
    if (!addInvoices) {
      commit("RESET_PAGE");
    }
    service
      .setInvoices(
        state.rangeStart,
        state.rangeEnd,
        custId,
        dateStart,
        dateEnd,
        status,
        sortBy,
        sortOrder,
        id,
        type
      )
      .then((response: any) => {
        commit("NEXT_RANGE");
        commit("SET_INVOICES", response);
        resolve({success: true, data: response})
        return response;
      })
      .catch((error: any) => {
        const notification = {
          type: "error",
          message: error,
        };
        dispatch("notification/add", notification, { root: true });
      }).finally(() => {
        commit("TOGGLE_LOADING");
      });
    });
  },
  async fetchInvoice({ commit, dispatch, state }, payload) {
    const pdf = state.invoiceView.find(
      (i) => i.invoice_num === payload.recordId
    );
    if (pdf !== undefined) {
      window.open(pdf.pdf, "_blank");
    } else {
      commit("ADD_ID_LOADING", payload.recordId);
      await service
        .getInvoice(payload.recordId)
        .then((resp: any) => {
          const bufferArray = Utils.base64ToArrayBuffer(resp);
          const blobStore = new Blob([bufferArray], {
            type: "application/pdf",
          });
          const data = window.URL.createObjectURL(blobStore);
          window.open(data, "_blank");
          commit("SET_INVOICE", { invoice_num: payload.recordId, pdf: data });
          commit("REMOVE_ID_LOADING", payload.recordId);
        })
        .catch((error) => {
          const notification = {
            type: "error",
            message: error,
          };
          dispatch("notification/add", notification, { root: true });
          commit("REMOVE_ID_LOADING", payload.recordId);
        });
    }
  },
  addInvoiceToPay({ commit, dispatch, state }, { arId, balance, dueDate }) {
    const invToPay = {
      arId,
      balance,
      dueDate,
    };
    commit("PUSH_INCOIVE_TO_PAY", invToPay);
  },
  removeInvoiceToPay({ commit }, { arId, balance, dueDate }) {
    commit("REMOVE_INVOICE_TO_PAY", { arId, balance, dueDate });
  },
  clearInvoicesToPay({ state, commit }) {
    commit("CLEAR_INVOICESTOPAY");
  },
};

export const invoice: Module<InvoiceState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
